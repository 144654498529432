@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
@media print,
screen and (min-width: 768px) {
  /*
  トップページ
  -------------------------------------*/
  .ipad{
    #Page.pageIndex{
      #MainImg{
        .mainImgBox{
          h2{
            left: -80px;
          }
        }
      }
      #ContBox01{
        #ContSubBox01{
          .innerBasic{
            .main{
              right: 250px;
            }
          }
        }
      }
    }
  }
  #Page.pageIndex {
    #MainImg{
      position: relative;
      padding-left: 240px;
      &::before{
        content: "";
        width: 100vw;
        height: 643px;
        background:  url(../img/contents/MainImg_pc_sub.png) 0 0 repeat-x;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        margin: auto;
      }
      &::after{
        content: "";
        width: 100vw;
        height: 30px;
        background:  url(../img/contents/stripe.jpg) 0 0 repeat-x;
        background-size: 202px;
        position: absolute;
        bottom: 60px;
        left: 0;
        z-index: -1;
        margin: auto;
      }
      .mainImgBox{
        margin: 0;
        min-width: 1040px;
        width: 100%;
        height: 643px;
        background: url(../img/contents/MainImg_pc.jpg) center no-repeat;
        background-size: cover;
        position: relative;
        &::before{
          content: "";
          height: 643px;
          width: 110%;
          background: url(../img/contents/dot.jpg) 0 0 repeat;
          background-size: 9px;
          position: absolute;
          left: -100px;
          bottom: -95px;
          z-index: -1;
        }
        h2{
          position: absolute;
          left: -153px;
          top: 124px;
          margin: auto;
          z-index: 1;
          span{
            display: block;
          }
          .span01,
          .span02{
            display: block;
            font-size: 101.8px;
            color: #0743a7;
            font-family: 'Amita', cursive;
            background: linear-gradient(-135deg, #308dc9, #1d4ea1);
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(255,255,255,0.0);
            letter-spacing: 0;
          }
          .span02{
            margin-top: -48px;
          }
          .span03{
            display: inline-block;
            font-size: 20px;
            color: #0743a7;
            line-height: 40px;
            text-align: left;
            font-weight: bold;
            letter-spacing: 0.35em;
            position: relative;
            right: 72px;
            bottom: 6px;
          }
        }
      }
    }
    #ContBox01{
      #ContSubBox01{
        margin-top: 175px;
        position: relative;
        .bgBox{
          position: absolute;
          left: 30px;
          right: 0;
          top: 60px;
          bottom: 0;
          margin: auto;
          width: 100%;
          height: 400px;
          background-color: #facf44;
        }
        .innerBasic{
          width: 100%;
          min-width:1220px;
          height: 400px;
          background: linear-gradient( -90deg, rgba(29, 78, 161, 0.9), rgba(48, 141, 201, 0.9)),
          url(../img/contents/business_bg.png);
          background-repeat: no-repeat;
          background-size: cover;
          margin: auto;
          position: relative;
          &::before{
            content: "Service";
            font-family: 'Amita', cursive;
            font-size: 190.79px;
            letter-spacing: 0;
            opacity: 0.05;
            line-height: 128px;
            position: absolute;
            right: 0;
            bottom: 0;
            color: #fff;
          }
          &::after{
            content: "";
            display:block;
            background: url(../img/contents/image01.png) 0 0 no-repeat;
            background-size: 570px auto;
            width: 570px;
            height: 436px;
            position: absolute;
            bottom: 0;
            right: 555px;
            margin: auto;
          }
          .main{
            text-align: center;
            padding-top: 42px;
            position: absolute;
            right: 158px;
            top: 0;
            bottom: 0;
            margin: auto;
            h3{
              padding-top: 63px;
              display: inline-block;
              position: relative;
              text-align: center;
              &::before{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                background: url(../img/contents/icon01.png) 0 0 no-repeat;
                background-size: 42px auto;
                width: 42px;
                height: 55px;
              }
              span{
                color: #fff;
              }
              .span01{
                font-size: 34px;
                font-weight: bold;
                letter-spacing: 0.2em;
                line-height: 49px;
              }
              .span02{
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.35em;
              }
            }
            p{
              color: #fff;
              font-size: 16px;
              line-height: 32px;
              letter-spacing: 0.35em;
              text-align: center;
              margin-bottom: 0;
              margin-top: 15px;
            }
            .more{
              border-color: #fff;
              &:hover{
                background-color: #fff;
                a{
                  color: #3295d6;
                }
              }
              &::before{
                background-color: #fff;
              }
              a{
                color: #fff;
                text-align: center;
              }
            }
          }
        }
      }
      #ContSubBox02{
        margin-top: 150px;
        margin-bottom: 120px;
        position: relative;
        &::before{
          content: "";
          display: block;
          position: absolute;
          top: -68px;
          left: 34px;
          right: 0;
          margin: auto;
          width: 793px;
          height: 676px;
          background: url(../img/contents/dot.jpg) 0 0 repeat;
          background-size: 9px;
        }
        &::after{
          content: "";
          display: block;
          position: absolute;
          top: 150px;
          left: 0;
          bottom: 0;
          right: 290px;
          margin: auto;
          width: 590px;
          height: 590px;
          background: url(../img/contents/stripe02.jpg) 0 0 repeat;
          background-size: 590px;
        }
        .innerBasic{
          width: auto;
          text-align: center;
          width: 1000px;
          &::after{
            content:"";
            display:block;
            clear:both;
          }
          .subBox{
            width: 500px;
            height: 500px;
            float: left;
            position: relative;
            z-index: 5;
            .box{
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%,-50%);
              -moz-transform: translate(-50%,-50%);
              -ms-transform: translate(-50%,-50%);
              -o-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
              padding-bottom: 19px;
              margin: auto;
              h4{
                position: relative;
                padding-top: 61px;
                letter-spacing: 0.2em;
                font-weight: bold;
                &::before{
                  content: "";
                  display: block;
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                  width: 53px;
                  height: 53px;
                }
                span{
                  font-weight: bold;
                  letter-spacing: 0.35em;
                  display: block;
                }
                .span01{
                  font-size: 34px;
                  line-height: 49px;
                  letter-spacing: 0.2em;
                }
                .span02{
                  font-size: 16px;
                }
              }
              .more{
                margin-top: 34px;
                height: 51px;
              }
            }
          }
          .subBox01{
            background: url(../img/contents/image04.jpg) 0 0 no-repeat;
            background-size: cover;
            .box01{
              h4{
                color: #3295d6;
                &::before{
                  background: url(../img/contents/icon02.png) 0 0 no-repeat;
                  background-size: 53px;
                }
                span{}
                .span01{}
                .span02{}
              }
              .more{
                border-color: #3295d6;
                &:hover{
                  background-color: #3295d6;
                  a{
                    color: #fff;
                  }
                }
                &::before{
                  background-color: #3295d6;
                }
                a{
                  color: #3295d6;
                }
              }
            }
          }
          .subBox02{
            background: url(../img/contents/image03.jpg) 0 0 no-repeat;
            background-size: cover;
            top: 60px;
            .box02{
              h4{
                color: #dc351f;
                &::before{
                  background: url(../img/contents/icon03.png) 0 0 no-repeat;
                  background-size: 53px;
                }
                span{}
                .span01{}
                .span02{}
              }
              .more{
                border-color: #dc351f;
                &:hover{
                  background-color: #dc351f;
                  a{
                    color: #fff;
                  }
                }
                &::before{
                  background-color: #dc351f;
                }
                a{color: #dc351f;}
              }
            }}
          }
        }
        #ContSubBox03{
          padding-top: 120px;
          padding-bottom: 78px;
          .innerBasic{
            width: 1000px;
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            .subBox01{
              width: 200px;
              float: left;
              h3{
                padding-top: 24px;
                position: relative;
                &::before{
                  content: "";
                  display: block;
                  background: url(../img/contents/icon05.png) 0 0 no-repeat;
                  background-size: 38px auto;
                  width: 38px;
                  height: 6px;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                .span01{
                  font-size: 34px;
                  font-weight: bold;
                  letter-spacing: 0.2em;
                  line-height: 49px;
                  position: relative;
                  left: -2px;
                }
                .span02{
                  font-size: 16px;
                  font-weight: bold;
                  letter-spacing: 0.35em;
                }
              }
              .more{
                width: 100px;
                height: 40px;
                margin-top: 23px;
                border-radius: 20px;
                border-color: #3295d6;
                position: relative;
                left: 22px;
                &:hover{
                  background-color: #3295d6;
                  a{
                    color: #fff;
                  }
                }
                &::before{
                  content: "";
                  display: block;
                  width: 30px;
                  height: 1px;
                  background-color: #3295d6;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  left: -21px;
                  right: auto;
                }
                a{
                  line-height: 40px;
                  font-size: 12px;
                  color: #3295d6;
                  text-align: center;
                }
              }
            }
            .subBox02{
              width: 800px;
              float: right;
              position: relative;
              top: -20px;
              ul{
                li{
                  border-top: 1px solid #e0e0e0;
                  padding: 18px 0 18px;
                  box-sizing: border-box;
                  &:first-of-type{
                    border: none;
                  }
                  dl{
                    &::after{
                      content: "";
                      display: block;
                      clear: both;
                    }
                    dt{
                      float:left;
                      width: 110px;
                      color: #888;
                      font-size: 14px;
                      line-height: 32px;
                    }
                    dd{
                      margin-left: 15px;
                      float:left;
                      max-width: 675px;
                      a{
                        font-size: 16px;
                        line-height: 32px;
                        font-weight: bold;
                        padding-left: 25px;
                        position: relative;
                        &:hover{
                          text-decoration:underline;
                        }
                        &::before{
                          content: "";
                          display: block;
                          width: 15px;
                          height: 15px;
                          background: url(../img/contents/icon04.png) 0 0 no-repeat;
                          background-size: 15px;
                          position: absolute;
                          top: 8px;
                          left: 0;
                          margin: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


  /*
  事業内容
  -------------------------------------*/
  #PageBusiness.pageIndex {
    #MainImg{
      background: url(../img/contents/01_business/main_img.jpg) center no-repeat;
      background-size: cover;
      margin-bottom: 20px;
      position: relative;
      &::after{
        content: "";
        display: block;
        background: url(../img/contents/stripe.jpg) 0 0 repeat-x;
        background-size: 207px;
        width: 100%;
        height: 20px;
        position: absolute;
        bottom: -20px;
      }
      h2{
        top: 130px;
        &::before{
          content: "";
          display: block;
          background: url(../img/contents/01_business/icon01.png) 0 0 no-repeat;
          background-size: 74px auto;
          width: 74px;
          height: 96px;
        }
        span{
        }
        .span01{
        }
        .span02{}
      }
    }
    .innerBasic{
      width: 1000px;
      #Main{
        #ContBox01{
          position: relative;
          &::before{
            content: "";
            display: block;
            background: url(../img/contents/dot.jpg) 0 0;
            background-size: 9px;
            width: 793px;
            height: 514px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            z-index: -1;
          }
          .contSubBox01{
            margin-top: 80px;
            height: 417px;
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            .subBox01{
              float: right;
              figure{
                width: 550px;
                height: 388px;
                position: relative;
                &::after{
                  content: "";
                  display: block;
                  width: 550px;
                  height: 388px;
                  background: linear-gradient( 90deg, #308dc9, #1d4ea1 );
                  z-index: -1;
                  position: absolute;
                  bottom: -29px;
                  left: -30px;
                }
                img{}
              }
            }
            .subBox02{
              float: left;
              width: 361px;
              margin-top: 94px;
              h3{
                font-size: 24px;
                line-height: 38px;
                letter-spacing: 0.085em;
                color: #0743a7;
                font-weight: bold;
              }
              p{
                margin-top: 19px;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.085em;
              }
            }
          }
          .contSubBox02{
            height: 417px;
            margin-top: 100px;
            margin-bottom: 180px;
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            .subBox01{
              float: left;
              figure{
                width: 550px;
                height: 388px;
                position: relative;
                &::after{
                  content: "";
                  display: block;
                  width: 550px;
                  height: 388px;
                  background: linear-gradient( -90deg, #308dc9, #1d4ea1 );
                  z-index: -1;
                  position: absolute;
                  bottom: -29px;
                  right: -30px;
                }
                img{}
              }
            }
            .subBox02{
              float: right;
              width: 361px;
              margin-top: 53px;
              h3{
                font-size: 24px;
                line-height: 38px;
                letter-spacing: 0.085em;
                color: #0743a7;
                font-weight: bold;
              }
              p{
                margin-top: 19px;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.085em;
              }
            }
          }
        }
      }
    }
  }

  /*
  会社案内
  -------------------------------------*/
  #PageCompany.pageIndex {
    #Container{
      position: relative;
      &::before{
        content: "";
        display: block;
        width: 3000px;
        height: 876px;
        background: url(../img/contents/dot.jpg) 0 0;
        background-size: 9px;
        position: absolute;
        left: 0;
        top: 305px;
        right: -2406px;
        margin: auto;
        z-index: -2;
      }
    }
    #MainImg{
      background: url(../img/contents/02_company/main_img.jpg) center no-repeat;
      background-size: cover;
      margin-bottom: 20px;
      position: relative;
      &::after{
        content: "";
        display: block;
        background: url(../img/contents/stripe.jpg) 0 0 repeat-x;
        background-size: 207px;
        width: 100%;
        height: 20px;
        position: absolute;
        bottom: -20px;
      }
      h2{
        top: 148px;
        &::before{
          content: "";
          display: block;
          background: url(../img/contents/02_company/icon01.png) 0 0 no-repeat;
          background-size: 80px auto;
          width: 80px;
          height: 80px;
        }
        span{
        }
        .span01{
        }
        .span02{}
      }
    }
    .innerBasic{
      #Main{
        nav{
          margin-top: 43px;
          ul{
            width: 534px;
            margin: auto;
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            li{
              float: left;
              margin-right: 45px;
              &:last-of-type{
                margin-right: 0;
              }
              a{
                padding-left: 25px;
                position: relative;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.1em;
                &:hover{
                  text-decoration: underline;
                }
                &::before{
                  content: "";
                  display: block;
                  background: url(../img/contents/02_company/arrow02.png) 0 0 no-repeat;
                  background-size: 15px;
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
            }
          }
        }
        #ContBox01{
          margin-top: 70px;
          &::after{
            content: "";
            display: block;
            clear: both;
          }
          .contSubBox01{
            float: left;
            width: 426px;
            margin-top: 55px;
            h3{
              margin-bottom: 45px;
              .span01{}
              .span02{}
            }
            p{
              font-size: 16px;
              line-height: 32px;
              letter-spacing: 0.1em;
            }
          }
          .contSubBox02{
            float: right;
            position: relative;
            &::after{
              content: "Philosophy";
              font-size: 120px;
              letter-spacing: 0.075em;
              color: #ffd13e;
              font-family: 'Amita', cursive;
              opacity: 0.5;
              position: absolute;
              bottom: -105px;
              left: -7px;
            }
            figure{
              margin-left: auto;
              width: 478px;
              height: 338px;
              position: relative;
              &::before{
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient( 90deg, #308dc9, #1d4ea1 );
                position: absolute;
                left: -30px;
                bottom: -30px;
                z-index: -1;
              }
              img{}
            }
            h4{
              margin-top: 89px;
              margin-bottom: 8px;
              color: #1d4ea1;
              font-size: 20px;
              font-weight: bold;
              line-height: 48px;
              letter-spacing: 0.35em;
            }
            p{
              position: relative;
              right: 4px;
              font-size: 34px;
              line-height: 63px;
              letter-spacing: 0.1em;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              .sign{
                font-size: 24px;
                line-height: 68px;
                letter-spacing: 0.1em;
                text-align: right;
                span{
                  display: inline-block;
                  padding-left: 43px;
                  position: relative;
                  left: 7px;
                  &::after{
                    content: "";
                    display: block;
                    width: 21px;
                    height: 1px;
                    background-color: #222;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
        #ContBox02{
          margin-top: 100px;
          h3{}
          ul{
            margin-top: 55px;
            &::after{
              content:"";
              display: block;
              clear: both;
            }
            li{
              float: left;
              width: 470px;
              border-bottom: 1px solid #ddd;
              height: 78px;
              &:first-of-type{
                width: 100%;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
              }
              &:nth-of-type(even) {
                margin-right: 60px;
              }
              dl{
                font-size: 0;
                line-height: 78px;
                dt{
                  letter-spacing: 0.35em;
                  display: inline-block;
                  font-weight: bold;
                  font-size: 16px;
                  width: 154px;
                }
                dd{
                  display: inline-block;
                  font-size: 16px;
                  letter-spacing:0.1em;
                }
              }
            }
            .listRight{
              dt{
                width: 178px;
              }
            }
          }
        }
        #ContBox03{
          margin-top: 120px;
          h3{
            margin-bottom: 43px;
          }
          .contSubBox{
            margin-bottom: 70px;
            &:last-of-type {
              margin-bottom: 0;
            }
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            .subBox01{
              margin-top: 55px;
              float: left;
              width: 320px;
              h4{
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 0.35em;
                font-weight: bold;
                margin-bottom: 11px;
              }
              dl{
                dt{
                  font-size: 16px;
                  letter-spacing: 0.1em;
                  line-height:32px;
                }
                dd{
                  font-size: 16px;
                  letter-spacing: 0.1em;
                  line-height:32px;
                }
              }
              .access{
                margin-top: 23px;
                font-size: 16px;
                letter-spacing: 0.1em;
                line-height:32px;
                padding-left: 50px;
                position: relative;
                &::before{
                  content: "";
                  display: block;
                  background: url(../img/contents/02_company/icon03.png) 0 0 no-repeat;
                  background-size: 38px auto;
                  width: 38px;
                  height: 46px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
            }
            .subBox02{
              float: right;
              width: 620px;
              span{
                margin-top: 10px;
                text-align: right;
                a{
                  color: #3295d6;
                  display: inline-block;
                  position: relative;
                  padding-left: 17px;
                  letter-spacing: 0.05em;
                  &::before{
                    content: "";
                    display: block;
                    background: url(../img/contents/02_company/arrow01.png) 0 0 no-repeat;
                    background-size: 8px;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  }
                  &:hover{
                    text-decoration: underline;
                  }
                }
              }
            }
          }
          .contSubBox03{
            .subBox01{
              margin-top: 75px;
              .access{
                margin-top: 38px;
              }
            }
          }
        }
        #ContBox04{
          margin-top: 120px;
          margin-bottom: 180px;
          h3{
            margin-bottom: 43px;
          }
          ul{
            li{
              margin-bottom: 28px;
              &:last-of-type{
                margin-bottom: 0;
              }
              dl{
                font-size: 0;
                dt{
                  width: 180px;
                  display: inline-block;
                  font-size: 24px;
                  line-height: 32px;
                  letter-spacing: 0.2em;
                  font-style: italic;
                  font-weight: bold;
                  position: relative;
                  &::after{
                    content: "";
                    display: block;
                    background: url(../img/contents/02_company/icon02.png) 0 0 no-repeat;
                    background-size: 14px;
                    height: 14px;
                    width: 14px;
                    position: absolute;
                    right: -3px;
                    top: 5px;
                    bottom: 0;
                    margin: auto;
                  }
                  span{
                    font-size: 36px;
                    color: #facf44;
                    display: inline;
                    font-style: italic;
                    letter-spacing: 1px;
                  }
                }
                dd{
                  margin-left: 204px;
                  color: #0743a7;
                  font-size: 16px;
                  line-height: 32px;
                  letter-spacing: 0.2em;
                  background-color: #f5f5f5;
                  display: inline-block;
                  position: relative;
                  padding: 17px 34px 13px 40px;
                  &::before{
                    content: "";
                    display: block;
                    width: 197px;
                    height: 1px;
                    background-color: #0743a7;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -204px;
                    margin: auto;
                  }
                  &::after{
                    content: "";
                    display: block;
                    background: url(../img/contents/02_company/icon02.png) 0 0 no-repeat;
                    background-size: 14px;
                    height: 14px;
                    width: 14px;
                    position: absolute;
                    left: -7px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お知らせ
  -------------------------------------*/
  #PageNews.pageIndex {
    #MainImg {
      width: 1000px;
      margin: auto;
      height: 297px;
      border-bottom: 3px solid #dddddd;
      h2{
        top: 78px;
      }
    }
    #TopicPath{
      margin-top: 27px;
      ol{
        li{
          &:nth-last-of-type(n+1){
            margin-right: 5px;
          }
        }
      }
    }
    #Main{
      #ContBox01{
        margin-bottom: 120px;
        ul{
          margin-top: 53px;
          margin-bottom: 73px;
          li{
            border-top: 1px solid #dddddd;
            &:first-of-type{
              border-top: none;
            }
            dl{
              padding: 19px 0 18px;
              &::after{
                content: "";
                display: block;
                clear: both;
              }
              dt{
                float: left;
                width: 126px;
                line-height: 32px;
                font-size: 14px;
                color: #888;
              }
              dd{
                width: 850px;
                float: left;
                a{
                  position: relative;
                  padding-left: 25px;
                  line-height: 32px;
                  font-size: 16px;
                  font-weight: bold;
                  display: inline-block;
                  &:hover{
                    text-decoration: underline;
                  }
                  &::before{
                    content: "";
                    position: absolute;
                    top: 8px;
                    left: 0;
                    display: block;
                    margin: auto;
                    width: 15px;
                    height: 15px;
                    background: url(../img/contents/icon04.png) 0 0 no-repeat;
                    background-size: 15px;
                  }
                }
              }
            }
          }
        }
        .more{
          border-color: #3295d6;
          display: block;
          margin: auto;
          &:hover{
            background-color: #3295d6;
            a{
              color: #fff;
            }
          }
          &::before {
            content: "";
            position: absolute;
            top: -20px;
            right: 0;
            left: 0;
            display: block;
            margin: auto;
            width: 1px;
            height: 30px;
            background: #3295d6;
          }
          a{
            color: #3295d6;
            text-align: center;
          }
        }
      }
    }
  }

  /*
  記事詳細ページ
  -------------------------------------*/
  #PageNews.pageEntry {
    #MainImg {
      width: 1000px;
      margin: auto;
      height: 297px;
      border-bottom: 3px solid #dddddd;
      h2{
        top: 78px;
      }
    }
    #TopicPath{
      margin-top: 27px;
      ol{
        li{
          &:nth-last-of-type(n+1){
            margin-right: 5px;
          }
        }
      }
    }
    #Main{
      #ContBox01{
        margin-bottom: 120px;
        h3{
          margin-top: 58px;
          margin-bottom: 45px;
          .date{
            padding-top: 40px;
            line-height: 32px;
            font-size: 14px;
            color: #888;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              width: 38px;
              height: 6px;
              background: url(../img/contents/icon05.png) 0 0 no-repeat;
              background-size: 38px auto;
            }
          }
          .title{
            font-size: 34px;
            line-height: 52px;
            font-weight: bold;
            letter-spacing: 0.07em;
          }
        }
        .entryBox{
          padding-bottom: 60px;
          border-bottom: 1px solid #dddddd;
          p{
            line-height: 32px;
            letter-spacing: 0.1em;
          }
        }
        #PageNation{
          text-align: center;
          font-size: 0;
          margin-top: 99px;
          li{
            display: inline-block;
            height: 48px;
            border: 1px solid #3295d6;
            border-radius: 25px;
            margin-right: 60px;
            position: relative;
            -webkit-transition: all .4s;
            -moz-transition: all .4s;
            -ms-transition: all .4s;
            -o-transition: all .4s;
            transition: all .4s;
            &:hover{
              -webkit-transition: all .4s;
              -moz-transition: all .4s;
              -ms-transition: all .4s;
              -o-transition: all .4s;
              transition: all .4s;
              background-color: #3295d6;
              a{
                color: #fff;
              }
            }
            &:last-of-type{
              margin-right: 0;
            }
            &::before{
              content: "";
              display: block;
              width: 30px;
              height: 1px;
              background-color: #3295d6;
              position: absolute;
            }
            a{
              font-size: 12px;
              letter-spacing: 0.1em;
              color: #3295d6;
              text-align: center;
              line-height: 48px;
              padding: 0 41px;
            }
          }
          .prev{
            &::before{
              right: -20px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
          .list{
            &::before{
              height: 30px;
              width: 1px;
              left: 0;
              right: 0;
              top: -20px;
              margin: auto;
            }
          }
          .next{
            &::before{
              left: -20px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }

/*
  採用情報
  -------------------------------------*/
  .gecko{
    #PageRecruit.pageIndex{
      #ContBox01{
        h3{
          .underline{
            &::after{
              bottom: 10px!important;
            }
          }
        }
      }
    }
  }
  #PageRecruit.pageIndex {
    #MainImg{
      position: relative;
      margin-bottom: 20px;
      background: url(../img/contents/04_recruit/main_img.jpg) center no-repeat;
      background-size: cover;
      &::after{
        content: "";
        position: absolute;
        bottom: -20px;
        display: block;
        width: 100%;
        height: 20px;
        background: url(../img/contents/04_recruit/stripe_r.jpg) 0 0 repeat-x;
        background-size: 1280px auto;
      }
      h2{
        top: 146px;
        &::before{
          content: "";
          display: block;
          width: 80px;
          height: 80px;
          background: url(../img/contents/04_recruit/icon01.png) 0 0 no-repeat;
          background-size: 80px auto;
        }
        span{
          background: linear-gradient(-90deg, #ff942b, #dc351f);
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255,255,255,0.0);
        }
        .span01{}
        .span02{}
      }
    }
    #Main{
      #ContBox01{
        margin-top: 80px;
        margin-bottom: 140px;
        .contSubBox01{
          border: 1px solid #222;
          padding: 42px 85px 40px;
          position: relative;
          margin-bottom: 120px;
          &::after{
            content: "";
            display: block;
            position: absolute;
            right: -34px;
            bottom: -29px;
            background: url(../img/contents/dot.jpg) 0 0;
            background-size: 9px;
            width: 100%;
            height: 100%;
            z-index: -2;
          }
          h3{
            font-weight: bold;
            font-size: 24px;
            line-height: 48px;
            border-bottom: 1px solid #222222;
            margin-bottom: 23px;
            padding-bottom: 15px;
            .underline{
              font-size: 34px;
              letter-spacing: 0.1em;
              display: inline-block;
              position: relative;
              &::after{
                content: "";
                display: block;
                width: 100%;
                height: 10px;
                background-color: #ffa82b;
                position: absolute;
                bottom: 3px;
                z-index: -1;
              }
            }
          }
          p{
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 0.1em;
            margin-bottom: 0;
          }
        }
        .contSubBox02{
          text-align: center;
          position: relative;
          &::before{
            content: "";
            display: block;
            background: url(../img/contents/04_recruit/stripe_o.jpg) 0 0;
            background-size: 384px;
            width: 384px;
            height: 89px;
            position: absolute;
            right: 130px;
            top: -29px;
          }
          &::after{
            content: "";
            display: block;
            background: url(../img/contents/04_recruit/stripe_o.jpg) 0 0;
            background-size: 384px;
            width: 170px;
            height: 170px;
            position: absolute;
            left: -60px;
            bottom: -54px;
          }
          ul{
            margin-bottom: 137px;
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            li{
              float: left;
              width: 333px;
              figure{
                .hovImg:hover + figcaption a{
                  text-decoration: underline;
                }
                figcaption{
                  margin-top: 10px;
                  text-align: center;
                  a{
                    display: inline-block;
                    font-size: 16px;
                    letter-spacing: 0.1em;
                    padding-left: 24px;
                    position: relative;
                    &::before{
                      content: "";
                      display: block;
                      background: url(../img/contents/04_recruit/arrow01.png) 0 0 no-repeat;
                      background-size: 15px;
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      left: 0;
                      bottom: 0;
                      top: -3px;
                      margin: auto;
                    }
                    &:hover{
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
            li:nth-of-type(2){
              position: relative;
              top: 30px;
            }
            li:last-of-type{
              position: relative;
              top: 60px;
            }
          }
          .more{
            margin-top: 0;
            width: 500px;
            height: 70px;
            border-color: #eb7c14;
            border-radius: 35px;
            &::before{
              background-color: #eb7c14;
            }
            a{
              color: #dc351f;
              font-size: 24px;
              letter-spacing: 0.35em;
              font-weight: bold;
              line-height: 70px;
              position: relative;
              &:hover{
                opacity: 0.7;
              }
              &::before{
                content: "";
                display: block;
                background: url(../img/contents/04_recruit/arrow01.png) 0 0 no-repeat;
                background-size: 15px;
                width: 15px;
                height: 15px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 30px;
                margin: auto;
              }
              span{
                position: relative;
                display: inline-block;
                padding-left: 37px;
                &::after{
                  content: "";
                  display: block;
                  background: url(../img/contents/04_recruit/icon02.png) 0 0 no-repeat;
                  background-size: 24px auto;
                  width: 24px;
                  height: 26px;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  margin: auto;
                }
              }
            }
          }
        }
      }
      #ContBox02{
        .contSubBox01{
          height: 312px;
          background: linear-gradient(-90deg, rgba(255, 148, 43, .95), rgba(220, 53, 27, .95)), url(../img/contents/04_recruit/recruit_bg.jpg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          .innerBasic{
            width: 620px;
            position: relative;
            &::after{
              content: "";
              display: block;
              background: url(../img/contents/04_recruit/img04.png) 0 0 no-repeat;
              background-size: 210px auto;
              width: 210px;
              height: 342px;
              position: absolute;
              right: -24px;
              bottom: 0;
            }
          }
          dl{
            color: #fff;
            font-weight: bold;
            padding: 74px 0;
            dt{
              position: relative;
              width: 200px;
              margin-bottom: 23px;
              padding-bottom: 16px;
              left: 65px;
              &::after{
                content: "";
                display: block;
                background-color: #fff;
                width: 130px;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
              }
              .span01{
                font-size: 34px;
                letter-spacing: 0.2em;
                line-height: 49px;
              }
              .span02{
                font-size: 16px;
                line-height: 36px;
                letter-spacing: 0.35em;
                text-align: center;
              }
            }
            dd{
              font-size: 0;
              span{
                font-size: 24px;
                line-height: 40px;
                letter-spacing: 0.35em;
                display: inline-block;
              }
              .span01{
                margin-right: 30px;
              }
              .span02{}
            }
          }
        }
        .contSubBox02{
          background-color: #fff5f2;
          padding-bottom: 86px;
          position: relative;
          &::after{
            content: "";
            display: block;
            background: url(../img/contents/04_recruit/img05.png) 0 0 no-repeat;
            background-size: 1014px auto;
            width: 1014px;
            height: 230px;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 643px;
            margin: auto;
          }
          .innerBasic{
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            h4{
              display: inline-block;
              line-height: 60px;
              font-size: 34px;
              font-weight: bold;
              letter-spacing: 0.07em;
              background: linear-gradient(-90deg, #ff942b, #dc351b);
              -webkit-background-clip: text;
              -webkit-text-fill-color: rgba(255, 255, 255, 0);
              margin-top: 96px;
              margin-bottom: 55px;
            }
            @-moz-document url-prefix() {
              h4{
                line-height: 32px;
              }
            }
            p{
              font-size: 16px;
              line-height: 32px;
              letter-spacing: 0.1em;
            }
            .subBox01{
              float: left;
              width: 480px;
            }
            .subBox02{
              float: right;
              width: 480px;
            }
          }
        }
      }
      #ContBox03{
        margin-top: 120px;
        margin-bottom: 125px;
        .innerBasic{
          h4{
            margin-bottom: 104px;
          }
          .contSubBox{
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            .subBox01{
              width: 550px;
              figure{
                position: relative;
                &::after{
                  content: "";
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  z-index: -1;
                }
                img{}
              }
            }
            .subBox02{
              margin-top: 7px;
              width: 390px;
              dl{
                margin-bottom: 72px;
                dt{
                  font-size: 16px;
                  line-height: 48px;
                  letter-spacing: 0.1em;
                  color: #888;
                }
                dd{
                  font-size: 24px;
                  line-height: 38px;
                  letter-spacing: 0.35em;
                  font-weight: bold;
                }
              }
              h5{
                font-weight: bold;
                color: #dc351f;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.2em;
                margin-bottom: 12px;
              }
              p{}
            }
            .subBox03{
              padding-top: 70px;
              clear: both;
              &::after{
                content: "";
                display: block;
                clear: both;
              }
              .box01{
                margin-top: 10px;
                float: left;
                width: 145px;
                h6{
                  padding-top: 47px;
                  font-size: 24px;
                  line-height: 32px;
                  letter-spacing: 0.2em;
                  font-weight: bold;
                  color: #dc351f;
                  position: relative;
                  &::before{
                    content: "";
                    display: block;
                    background: url(../img/contents/04_recruit/icon03.png) 0 0 no-repeat;
                    background-size: 38px auto;
                    width: 38px;
                    height: 34px;
                    position: absolute;
                    left: 0;
                    top: 0;
                  }
                }
              }
              .box02{
                float: right;
                width: 781px;
                p{}
              }
            }
          }
          .contSubBox01{
            margin-bottom: 125px;
            .subBox01{
              float: right;
              figure{
                &::after{
                  background: linear-gradient(-90deg, #dc351b, #ff942b);
                  left: -30px;
                  bottom: -30px;
                }
              }
            }
            .subBox02{
              float: left;
            }
          }
          .contSubBox02{
            .subBox01{
              float: left;
              figure{
                &::after{
                  background: linear-gradient(90deg, #dc351b, #ff942b);
                  right: -30px;
                  bottom: -30px;
                }
              }
            }
            .subBox02{
              margin-top: 16px;
              float: right;
              width: 380px;
            }
          }
        }
      }
      #ContBox04{
        background-color: #fafafa;
        padding-bottom: 187px;
        padding-top: 79px;
        .innerBasic{
          &::after{
            content: "";
            display: block;
            clear: both;
          }
          h4{margin-bottom: 54px;}
          .contSubBox{
            width: 470px;
            figure{
              position: relative;
              margin-bottom: 26px;
              padding-bottom: 20px;
              &::after{
                content: "";
                display: block;
                background: url(../img/contents/04_recruit/stripe_r.jpg) 0 0 repeat-x;
                background-size: 1280px;
                width: 100%;
                height: 20px;
                position: absolute;
                bottom: 0;
              }
            }
            dl{
              dt{
                position: relative;
                padding-left: 40px;
                font-size: 24px;
                line-height: 48px;
                letter-spacing: 0.1em;
                font-weight: bold;
                margin-bottom: 5px;
              }
              dd{
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.09em;
              }
            }
          }
          .contSubBox01{
            float: left;
            dl{
              dt{
                &::before {
                  content: "";
                  display: block;
                  background: url(../img/contents/04_recruit/icon04.png) 0 0 no-repeat;
                  background-size: 30px auto;
                  width: 30px;
                  height: 38px;
                  position: absolute;
                  left: 0;
                  top: 3px;
                  bottom: 0;
                }
              }
            }
          }
          .contSubBox02{
            float: right;
            dl{
              dt{
                padding-left: 45px;
                &::before {
                  content: "";
                  display: block;
                  background: url(../img/contents/04_recruit/icon05.png) 0 0 no-repeat;
                  background-size: 36px auto;
                  width: 36px;
                  height: 31px;
                  position: absolute;
                  left: 0;
                  top: 5px;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
      #ContBox05{
        margin-bottom: 175px;
        .contSubBox01{
          height: 212px;
          background: linear-gradient(-90deg, rgba(255, 148, 43, .95), rgba(220, 53, 27, .95)), url(../img/contents/04_recruit/recruit_bg02.jpg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          .innerBasic{
            padding: 62px 0;
            position: relative;
            &::before{
              content: "";
              display: block;
              background: url(../img/contents/04_recruit/img10.png) 0 0 no-repeat;
              background-size: 222px auto;
              width: 222px;
              height: 290px;
              position: absolute;
              left: 26px;
              bottom: 0;
            }
            &::after{
              content: "";
              display: block;
              background: url(../img/contents/04_recruit/img11.png) 0 0 no-repeat;
              background-size: 193px auto;
              width: 193px;
              height: 273px;
              position: absolute;
              right: 9px;
              bottom: 0;
            }
            span{
              color: #fff;
              font-weight: bold;
              text-align: center;
            }
            .span01{
              font-size: 34px;
              line-height: 49px;
              letter-spacing: 0.2em;
            }
            .span02{
              font-size: 16px;
              line-height: 40px;
              letter-spacing: 0.35em;
            }
          }
        }
        .contSubBox02{
          margin-top: 58px;
          p{
            font-weight: bold;
            font-size: 20px;
            line-height: 42px;
            letter-spacing: 0.1em;
            text-align: center;
          }
          table{
            border-top: 1px solid #dddddd;
            margin-top: 59px;
            width: 100%;
            tr{
              border-bottom: 1px solid #dddddd;
              th{
                font-size: 16px;
                font-weight: bold;
                line-height: 32px;
                letter-spacing: 0.35em;
                width: 215px;
              }
              td{
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.08em;
                padding: 22px 0 21px;
                span{
                  color: #888;
                  display:inline-block;
                  margin-left: 25px;
                }
                a{
                  display: inline-block;
                  font-size: 24px;
                  line-height: 32px;
                  letter-spacing: 0.08em;
                  font-weight: bold;
                  color: #dc351f;
                  font-style: italic;
                }
              }
            }
            tr:last-of-type{
              span{
                margin-left: 0;
                margin-top: 23px;
                color: #222;
              }
              a{
                padding-left: 17px;
              }
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact.pageIndex {
    #MainImg{
      width: 1000px;
      margin: auto;
      height: 299px;
      border-bottom: 3px solid #dddddd;
      h2{
        top: 77px;
        span{
          background: linear-gradient(-90deg, #f3cb49, #f09937);
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255,255,255,0.0);
        }
      }
    }
    #TopicPath{
      margin-top: 27px;
    }
    #Main{
      #ContBox01{
        margin-top: 75px;
        margin-bottom: 120px;
        .innerBasic{
          p{
            text-align: center;
            a{
              display: inline;
              color: #3295d6;
              &:hover{
                text-decoration:underline;
              }
            }
          }
          table{
            width: 100%;
            border-top: 1px solid #dddddd;
            margin-top: 67px;
            margin-bottom: 70px;
            padding-top: 50px;
            display: block;
            tr{
              border-bottom: 1px dotted #dddddd;
              &:last-of-type{
                border: none;
              }
              th{
                width: 287px;
                padding-left: 47px;
                padding-top: 42px;
                position: relative;
                vertical-align: top;
                &::before{
                  content: "";
                  display:block;
                  width: 33px;
                  height: 18px;
                  border-radius:2px;
                  font-size: 11px;
                  color: #fff; 
                  line-height: 18px;
                  text-align: center;
                  letter-spacing: 0.05em;
                  position: absolute;
                  top: 44px;
                  left: 0;
                }
              }
              th.require{
                &::before{
                  content: "必須";
                  background-color: #dc351f;
                }
              }
              th.any{
                &::before{
                  content: "任意";
                  background-color: #b1b1b1;
                }
              }
              td{
                padding: 30px 0;
                .wpcf7-list-item{
                  margin-left: 0;
                  label{
                    margin-right: 43px;
                    position: relative;
                    top: 2px;
                  }
                  &:last-of-type{
                    label{
                      margin-right: 0;
                    }
                  }
                  .wpcf7-list-item-label{
                    display: inline-block;
                  }
                  .radio {
                    margin-right: 10px;
                    display: inline-block;
                    span{
                      display: inline-block;
                      width: 27px;
                      height: 27px;
                      background-color: #fafafa;
                      border: 1px solid #cccccc;
                      border-radius: 50%;
                      position: relative;
                      top: -2px;
                      input{
                        opacity:0;
                      }
                    }
                    span.checked{
                      &::after{
                        content: "";
                        display: block;
                        width: 11px;
                        height: 11px;
                        border-radius: 50%;
                        background-color: #ffa33b;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                      }
                    }
                  }
                }
                input:focus,
                textarea:focus{
                  border-color: #ffa33b;
                  outline: 0;
                }
                input,textarea{
                  background-color: #fafafa;
                  border: 1px solid #cccccc;
                  letter-spacing: 0.05em;
                  resize: vertical;
                  width: 100%;
                  padding: 8px 20px 7px;
                  font-size: 16px;
                  &::placeholder{
                    color: #bbbbbb;
                    opacity: 1;
                  }
                  &:-ms-input-placeholder{
                    color: #bbbbbb;
                  }
                }
                textarea{
                  height: 300px;
                }
                input.size01{
                  width: 500px;
                }
                input.size02{
                  width: 380px;
                }
                input.size03{
                  width: 240px;
                }
                input.size04{
                  width: 681px;
                }
                input.size05{
                  width: 270px;
                }
                input.size06{
                  width: 439px;
                }
              }
              td.zip{
                padding-left: 30px;
                position: relative;
                &::before{
                  content: "〒";
                  font-weight: bold;
                  display: block;
                  position: absolute;
                  left: 0;
                  top: 40px;
                }
                #zip{
                  margin-bottom: 20px;
                }
                #zip.wpcf7c-conf{
                  margin-bottom: 0;
                }
              }
            }
            tr.textarea{
              td{
                padding-bottom: 23px;
              }
            }
            tr.select{
              td{
                select{
                  width: 270px;
                  background-color: #fafafa;
                  position: relative;
                  background: url(../img/contents/icon06.png) center right 20px no-repeat;
                  background-size: 10px;
                  padding: 8px 20px 7px;
                  border: 1px solid #ccc;
                }
              }
            }
            tr.radio01{
              th{
                padding-top: 35px;
                &::before{
                  top: 37px;
                }
              }
            }
            tr.radio02{
              th{
                padding-top: 27px;
                &::before{top: 29px;}
              }
              td{padding-top: 23px;}
            }
          }
          .btn01{
            margin: auto;
            width: 320px;
            height: 70px;
            line-height: 70px;
            border-radius: 35px;
            font-size: 16px;
            letter-spacing: 0.1em;
            -webkit-transition: all .4s;
            -moz-transition: all .4s;
            -ms-transition: all .4s;
            -o-transition: all .4s;
            transition: all .4s;
            &:hover{
              opacity: 0.7;
              -webkit-transition: all .4s;
              -moz-transition: all .4s;
              -ms-transition: all .4s;
              -o-transition: all .4s;
              transition: all .4s;
            }
          }
        }
      }
      #ContBox02{
        background-color: #fafafa;
        padding-top: 100px;
        .innerBasic{
          .contSubBox{
            border-bottom: 1px solid #dddddd;
            padding-bottom: 38px;
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            h4{
              margin-bottom: 43px;
            }
            .subBox{
              width: 490px;
              p{
                letter-spacing: 0.05em;
              }
              p.num{
                padding-left: 15px;
                position: relative;
                &::before{
                  content: "";
                  display: block;
                  font-size: 16px;
                  line-height: 32px;
                  letter-spacing:0.05em;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
              p.num01{&::before{content: "1.";}}
              p.num02{&::before{content: "2.";}}
              p.num03{&::before{content: "3.";}}
              p.num04{&::before{content: "4.";}}
              p.num05{&::before{content: "5.";}}
              p.num06{&::before{content: "6.";}}
              span{
                text-align: right;
                padding-top: 5px;
              }
            }
            .subBox01{
              float: left;
            }
            .subBox02{
              float: right;
              width: 480px;
            }
          }
          .contSubBox02{
            padding-bottom: 80px;
            .subBox01{
              width: 471px;
            }
            .subBox02{
              width: 470px;
            }
            h4{
              margin-top: 60px;
            }
            h5{
              font-size: 20px;
              line-height: 32px;
              letter-spacing: 0.01em;
              font-weight: bold;
              margin-bottom: 10px;
            }
            p{
              margin-bottom: 30px;
            }
          }
        }
      }
    }
    .wpcf7-form-control-wrap.corporation{
      display: inline-block;
      &:first-of-type{
        margin-right: 50px;
      }
    }
    .wpcf7-form-control-wrap.method{
      display: inline-block;
      margin-right: 50px;
      &:last-of-type{
        margin-right: 0;
      }
    }
  }

  /*
  確認画面
  -------------------------------------*/
  #PageContact.pageIndex{
   input.wpcf7c-conf, textarea.wpcf7c-conf, select.wpcf7c-conf {
    background: none!important;
    border: none!important;
    padding-left: 0!important;
    padding-right: 0!important;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  #Main #ContBox01 .innerBasic table tr.radio01 th.wpcf7c-elm-step2{
    padding-top: 38px;
    &::before{
      top: 40px;
    }
  }
  #Main #ContBox01 .innerBasic table tr.radio02 th.wpcf7c-elm-step2{
    padding-top: 33px;
    &::before{
      top: 35px;
    }
  }
  .wpcf7c-elm-step2 p#textArea {
    margin: 6px 0 10px;
    text-align: left!important;
  }
  .wpcf7-submit{
    margin: auto;
    border-radius: 35px;
    width: 320px;
    height: 70px;
    line-height: 70px;
    letter-spacing: .1em;
    font-size: 16px;
  }
  .prev{
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #3295d6;
    display: inline-block;
    margin-top: 31px;
    &:hover{
      text-decoration: underline;
    }
  }
  .ajax-loader{
    display: none;
  }
  .prevBox{
    text-align: center;
  }
}

  /*
  送信完了画面
  -------------------------------------*/
  #PageContact.pageThanks {
    #MainImg{
      width: 1000px;
      margin: auto;
      height: 298px;
      border-bottom: 3px solid #dddddd;
      h2{
        top: 77px;
        span{
          background: linear-gradient(-90deg, #f3cb49, #f09937);
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255,255,255,0.0);
        }
      }
    }
    #TopicPath{
      margin-top: 28px;
    }
    #Main{
      #ContBox01{
        margin-top: 73px;
        margin-bottom: 62px;
        p{
          text-align: center;
        }
        .addressBox{
          margin-top: 31px;
          height: 136px;
          width: 600px;
          margin: auto;
          text-align: center;
          border: 1px solid #222222;
          position: relative;
          &::after {
            content: '';
            display: block;
            background: url(../img/contents/dot.jpg) 0 0;
            background-size: 9px;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 35px;
            top: 30px;
            z-index:-1;
          }
          a{
            margin: 40px 0;
            display: inline-block;
            font-size: 34px;
            font-weight: bold;
            letter-spacing: 0.1em;
            position: relative;
            &:hover{
              &::after{
                display: none;
              }
            }
            &::after{
              content: "";
              display: block;
              height: 2px;
              width: 100%;
              position: absolute;
              bottom: 7px;
              background-color: #222;
            }
          }
        }
        .moreBox{
          text-align: center;
          .more{
            width: 188px;
            margin-top: 100px;
            border-color: #3295d6;
            &:hover{
              background-color: #3295d6;
              a{
                color: #fff;
              }
            }
            &::before{
              background-color: #3295d6;
            }
            a{
              color: #3295d6;
            }
          }
        }
      }
    }
  }


}
@media print,
screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg{
      position: relative;
      margin-bottom: 120px;
      &::after{
        content: "";
        display: block;
        width: 100%;
        height: 165px;
        background:  url(../img/contents/MainImg_sp_sub.png) 0 0 repeat-x;
        background-size: auto 165px;
        position: absolute;
        bottom: -120px;
        left: 0;
        z-index:-1;
      }
      .mainImgBox{
        margin: 0 0 0 auto;
        height: 222px;
        background: url(../img/contents/MainImg_sp.png) center no-repeat;
        background-size: cover;
        position: relative;
        &::before{
          content: "";
          height: 30px;
          width: calc(100% - 30px);
          background: url(../img/contents/dot.jpg) 0 0 repeat;
          background-size: 9px;
          position: absolute;
          right: 0;
          bottom: -152px;
          z-index: -1;
        }
        h2{
          display: inline-block;
          margin: auto;
          z-index: 1;
          position: absolute;
          bottom: -92px;
          left: 13px;
          span{
            display: block;
          }
          .span01,
          .span02{
            font-size: 55px;
            color: #0743a7;
            font-family: 'Amita', cursive;
            background: linear-gradient(-135deg, #308dc9, #1d4ea1);
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(255,255,255,0.0);
            letter-spacing: 0;
          }
          .span02{
            margin-top: -32px;
          }
        }
        .span03.disp_pc{
          display:none;
        }
        .span03.disp_sp{
          display: inline-block;
          font-size: 12px;
          color: #2f88c2;
          line-height: 22px;
          text-align: right;
          font-weight: bold;
          letter-spacing: 0.35em;
          position: absolute;
          right: 7px;
          bottom: -77px;
        }
      }
    }
    #ContBox01{
      padding-top: 60px;
      #ContSubBox01{
        position: relative;
        .bgBox{
          content: "";
          display: block;
          background-color: #facf44;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 15px;
          left: 15px;
          z-index: -1;
        }
        .innerBasic{
          height: 600px;
          background: linear-gradient( -90deg, rgba(29, 78, 161, 0.9), rgba(48, 141, 201, 0.9)),
          url(../img/contents/business_bg_sp.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto 600px;
          margin: 0;
          position: relative;
          &::before{
            content: "";
            display: block;
            background: url(../img/contents/image01.png) 0 0 no-repeat;
            background-size: 284px auto;
            width: 284px;
            height: 218px;
            position: absolute;
            left: 10px;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
          }
          &::after{
            content: "Service";
            font-family: 'Amita', cursive;
            font-size: 103.1px;
            letter-spacing: 0;
            text-align: center;
            opacity: 0.05;
            line-height: 70px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            color: #fff;
          }
          .main{
            text-align: center;
            padding-top: 55px;
            h3{
              padding-top: 63px;
              display: inline-block;
              position: relative;
              text-align: center;
              &::before{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                background: url(../img/contents/icon01.png) 0 0 no-repeat;
                background-size: 42px auto;
                width: 42px;
                height: 55px;
              }
              span{
                color: #fff;
              }
              .span01{
                font-size: 34px;
                font-weight: bold;
                letter-spacing: 0.2em;
                line-height: 49px;
              }
              .span02{
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.35em;
              }
            }
            p{
              color: #fff;
              font-size: 16px;
              line-height: 32px;
              letter-spacing: 0.35em;
              text-align: center;
              margin-bottom: 0;
              margin-top: 15px;
            }
            .more{
              border-color: #fff;
              &::before{
                background-color: #fff;
              }
              a{
                color: #fff;
                text-align: center;
              }
            }
          }
        }
      }
      #ContSubBox02{
        margin-top: 135px;
        margin-bottom: 120px;
        position: relative;
        &::before{
          content: "";
          display: block;
          position: absolute;
          top: -75px;
          left: 45px;
          right: 0;
          margin: auto;
          height: 928px;
          background: url(../img/contents/dot.jpg) 0 0 repeat;
          background-size: 9px;
        }
        &::after{
          content: "";
          display: block;
          position: absolute;
          top: -30px;
          left: 0;
          bottom: 0;
          width: 24%;
          margin: auto;
          height: 714px;
          background: url(../img/contents/stripe02.jpg) 0 0 repeat;
          background-size: 590px;
        }
        .innerBasic{
          width: auto;
          text-align: center;
          margin: 0 30px;
          &::after{
            content:"";
            display:block;
            clear:both;
          }
          .subBox{
            width: 100%;
            height: 315px;
            position: relative;
            z-index: 5;
            .box{
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%,-50%);
              -moz-transform: translate(-50%,-50%);
              -ms-transform: translate(-50%,-50%);
              -o-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
              padding-bottom: 29px;
              margin: auto;
              h4{
                position: relative;
                padding-top: 61px;
                letter-spacing: 0.2em;
                font-weight: bold;
                &::before{
                  content: "";
                  display: block;
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                  width: 53px;
                  height: 53px;
                }
                span{
                  font-weight: bold;
                  letter-spacing: 0.35em;
                  display: block;
                }
                .span01{
                  font-size: 34px;
                  line-height: 49px;
                  letter-spacing:0.2em;
                }
                .span02{
                  font-size: 16px;
                }
              }
              .more{
                position: absolute;
                bottom: -145px;
                left: 0;
                right: 0;
                margin: auto;
              }
            }
          }
          .subBox01{
            background: url(../img/contents/image04.jpg) center no-repeat;
            background-size: cover;
            left: 15px;
            .box01{
              h4{
                color: #3295d6;
                &::before{
                  background: url(../img/contents/icon02.png) 0 0 no-repeat;
                  background-size: 53px;
                }
                span{}
                .span01{}
                .span02{}
              }
              .more{
                border-color: #3295d6;
                &::before{
                  background-color: #3295d6;
                }
                a{color: #3295d6;}
              }
            }
          }
          .subBox02{
            background: url(../img/contents/image03.jpg) center no-repeat;
            background-size: cover;
            top: 60px;
            right: 15px;
            margin-top: 54px;
            .box02{
              h4{
                color: #dc351f;
                &::before{
                  background: url(../img/contents/icon03.png) 0 0 no-repeat;
                  background-size: 53px;
                }
                span{}
                .span01{}
                .span02{}
              }
              .more{
                border-color: #dc351f;
                &::before{
                  background-color: #dc351f;
                }
                a{color: #dc351f;}
              }
            }}
          }
        }
        #ContSubBox03{
          padding-top: 110px;
          padding-bottom: 113px;
          .innerBasic{
            margin: 0 15px;
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            .subBox01{
              margin-bottom: 11px;
              &::after{
                content: "";
                display: block;
                clear: both;
              }
              h3{
                padding-top: 24px;
                position: relative;
                float:left;
                &::before{
                  content: "";
                  display: block;
                  background: url(../img/contents/icon05.png) 0 0 no-repeat;
                  background-size: 38px auto;
                  width: 38px;
                  height: 6px;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                span{
                  position: relative;
                  left: -2px;
                }
                .span01{
                  font-size: 34px;
                  font-weight: bold;
                  letter-spacing: 0.2em;
                  line-height: 49px;
                }
                .span02{
                  font-size: 16px;
                  font-weight: bold;
                  letter-spacing: 0.35em;
                }
              }
              .more{
                margin-top: 30px;
                float: right;
                width: 100px;
                height: 40px;
                border-radius: 20px;
                border-color: #3295d6;
                &::before{
                  content: "";
                  display: block;
                  width: 30px;
                  height: 1px;
                  background-color: #3295d6;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  left: -21px;
                  right: auto;
                }
                a{
                  line-height: 40px;
                  font-size: 12px;
                  color: #3295d6;
                  text-align: center;
                }
              }
            }
            .subBox02{
              ul{
                li{
                  border-top: 1px solid #e0e0e0;
                  padding: 20px 0 22px;
                  box-sizing: border-box;
                  &:first-of-type{
                    border: none;
                  }
                  dl{
                    &::after{
                      content: "";
                      display: block;
                      clear: both;
                    }
                    dt{
                      width: 110px;
                      color: #888;
                      font-size: 14px;
                      line-height: 32px;
                    }
                    dd{
                      a{
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: bold;
                        padding-left: 25px;
                        position: relative;
                        letter-spacing: 0.1em;
                        text-align: justify;
                        &::before{
                          content: "";
                          display: block;
                          width: 15px;
                          height: 15px;
                          background: url(../img/contents/icon04.png) 0 0 no-repeat;
                          background-size: 15px;
                          position: absolute;
                          top: 5px;
                          left: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  /*
  事業内容
  -------------------------------------*/
  #PageBusiness.pageIndex {
    #MainImg{
      background: url(../img/contents/01_business/main_img.jpg) center no-repeat;
      background-size: cover;
      margin-bottom: 20px;
      position: relative;
      &::after{
        content: "";
        display: block;
        background: url(../img/contents/stripe.jpg) 0 0 repeat-x;
        background-size: 202px;
        width: 100%;
        height: 15px;
        position: absolute;
        bottom: -15px;
      }
      h2{
        &::before{
          content: "";
          display: block;
          background: url(../img/contents/01_business/icon01.png) 0 0 no-repeat;
          background-size: 55px auto;
          width: 55px;
          height: 72px;
        }
        span{
        }
        .span01{
        }
        .span02{}
      }
    }
    .innerBasic{
      margin: 0 15px;
      #Main{
        #ContBox01{
          position: relative;
          .contSubBox01{
            margin-top: 60px;
            position: relative;
            &::before{
              content: "";
              display: block;
              background: url(../img/contents/dot.jpg) 0 0;
              background-size: 9px;
              width: calc(100% - 65px);
              height: 496px;
              position: absolute;
              top: 121px;
              right: -5px;
              z-index: -1;
            }
            .subBox01{
              figure{
                margin-left: 15px;
                position: relative;
                &::after{
                  content: "";
                  display: block;
                  background: linear-gradient( 90deg, #308dc9, #1d4ea1 );
                  width: 100%;
                  height: 100%;
                  z-index: -1;
                  position: absolute;
                  bottom: -15px;
                  left: -15px;
                }
                img{}
              }
            }
            .subBox02{
              margin-top: 47px;
              h3{
                font-size: 24px;
                line-height: 38px;
                letter-spacing: 0.085em;
                color: #0743a7;
                font-weight: bold;
                text-align: justify;
              }
              p{
                margin-top: 19px;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.085em;
              }
            }
          }
          .contSubBox02{
            margin-top: 89px;
            margin-bottom: 180px;
            position: relative;
            &::before{
              content: "";
              display: block;
              background: url(../img/contents/dot.jpg) 0 0;
              background-size: 9px;
              width: calc(100% - 65px);
              height: 496px;
              position: absolute;
              top: 128px;
              left: -6px;
              z-index: -1;
            }
            .subBox01{
              figure{
                position: relative;
                margin-right: 15px;
                &::after{
                  content: "";
                  display: block;
                  background: linear-gradient( -90deg, #308dc9, #1d4ea1 );
                  width: 100%;
                  height: 100%;
                  z-index: -1;
                  position: absolute;
                  bottom: -15px;
                  right: -15px;
                }
                img{}
              }
            }
            .subBox02{
              margin-top: 42px;
              h3{
                font-size: 24px;
                line-height: 38px;
                letter-spacing: 0.085em;
                color: #0743a7;
                font-weight: bold;
                text-align: justify;
                margin-bottom: 19px;
              }
              p{
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.085em;
              }
            }
          }
        }
      }
    }
  }

  /*
  会社案内
  -------------------------------------*/
  #PageCompany.pageIndex {
    #Container{
      position: relative;
    }
    #MainImg{
      background: url(../img/contents/02_company/main_img.jpg) center no-repeat;
      background-size: cover;
      margin-bottom: 20px;
      position: relative;
      &::after{
        content: "";
        display: block;
        background: url(../img/contents/stripe.jpg) 0 0 repeat-x;
        background-size: 202px;
        width: 100%;
        height: 15px;
        position: absolute;
        bottom: -15px;
      }
      h2{
        top: 58px;
        &::before{
          content: "";
          display: block;
          background: url(../img/contents/02_company/icon01.png) 0 0 no-repeat;
          background-size: 60px auto;
          width: 60px;
          height: 60px;
        }
        span{
        }
        .span01{
        }
        .span02{}
      }
    }
    .innerBasic{
      #Main{
        nav{
          margin-top: 60px;
          ul{
            font-size: 0;
            li{
              display: inline-block;
              width: 49.7%;
              border-right: 1px solid #dddddd;
              border-bottom: 1px solid #dddddd;
              &:first-of-type {
                border-left: 1px solid #dddddd;
                border-top: 1px solid #dddddd;
              }
              &:nth-of-type(2){
                border-top: 1px solid #dddddd;
              }
              &:nth-of-type(3){
                border-left: 1px solid #dddddd;
              }
              &:last-of-type{
                margin-right: 0;
              }
              a{
                padding: 6px 0;
                width: 100%;
                text-align: center;
                span{
                  padding-left: 25px;
                  position: relative;
                  font-size: 16px;
                  line-height: 32px;
                  letter-spacing: 0.1em;
                  display: inline-block;
                  &::before{
                    content: "";
                    display: block;
                    background: url(../img/contents/02_company/arrow02.png) 0 0 no-repeat;
                    background-size: 15px;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
        #ContBox01{
          margin-top: 80px;
          position: relative;
          &::before{
            content: "";
            display: block;
            width: 100%;
            height: 663px;
            background: url(../img/contents/dot.jpg) 0 0;
            background-size: 9px;
            position: absolute;
            right: -60px;
            bottom: -87px;
            z-index: -2;
          }
          &::after{
            content: "";
            display: block;
            clear: both;
          }
          .contSubBox01{
            h3{
              margin-bottom: 45px;
              .span01{}
              .span02{}
            }
            p{
              font-size: 16px;
              line-height: 32px;
              letter-spacing: 0.05em;
            }
          }
          .contSubBox02{
            margin-top: 30px;
            position: relative;
            &::after{
              content: "Philosophy";
              font-size: 60px;
              letter-spacing: 0.075em;
              color: #ffd13e;
              font-family: 'Amita', cursive;
              opacity: 0.5;
              position: absolute;
              bottom: -70px;
              right: -36px;
            }
            figure{
              position: relative;
              margin-left: 15px;
              &::before{
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient( 90deg, #308dc9, #1d4ea1 );
                position: absolute;
                left: -15px;
                bottom: -15px;
                z-index: -1;
              }
              img{}
            }
            h4{
              margin-top: 41px;
              margin-bottom: 12px;
              color: #1d4ea1;
              font-size: 20px;
              font-weight: bold;
              line-height: 48px;
              letter-spacing: 0.35em;
            }
            p{
              position: relative;
              left: -5px;
              font-size: 24px;
              line-height: 48px;
              letter-spacing: 0.05em;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              .sign{
                font-size: 18px;
                line-height: 68px;
                letter-spacing: 0.1em;
                text-align: right;
                span{
                  display: inline-block;
                  position: relative;
                  left: 6px;
                  padding-left: 22px;
                  &::before{
                    content: "";
                    display: block;
                    width: 11px;
                    height: 1px;
                    background-color: #222;
                    position: absolute;
                    top: -3px;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
        #ContBox02{
          margin-top: 140px;
          h3{}
          ul{
            margin-top: 36px;
            &::after{
              content:"";
              display: block;
              clear: both;
            }
            li{
              border-bottom: 1px solid #ddd;
              &:first-of-type{
                width: 100%;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
              }
              dl{
                font-size: 0;
                padding: 26px 0 25px;
                dt{
                  letter-spacing: 0.35em;
                  display: inline-block;
                  font-weight: bold;
                  font-size: 16px;
                  width: 150px;
                  padding-left: 15px;
                }
                dd{
                  display: inline-block;
                  font-size: 16px;
                  letter-spacing:0.1em;
                  a{
                    text-decoration: underline;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
        #ContBox03{
          margin-top: 70px;
          h3{
          }
          .contSubBox{
            margin-bottom: 35px;
            padding-bottom: 35px;
            border-bottom: 1px solid #dddddd;
            &:last-of-type {
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none;
            }
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            .subBox01{
              margin-top: 53px;
              h4{
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 0.35em;
                font-weight: bold;
                margin-bottom: 11px;
              }
              dl{
                dt{
                  font-size: 16px;
                  letter-spacing: 0.1em;
                  line-height:32px;
                }
                dd{
                  font-size: 16px;
                  letter-spacing: 0.1em;
                  line-height:32px;
                  text-align: justify;
                }
              }
              .access{
                margin: 22px 0 30px;
                font-size: 16px;
                letter-spacing: 0.1em;
                line-height:32px;
                padding-left: 50px;
                position: relative;
                &::before{
                  content: "";
                  display: block;
                  background: url(../img/contents/02_company/icon03.png) 0 0 no-repeat;
                  background-size: 38px auto;
                  width: 38px;
                  height: 46px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }
              }
            }
            .subBox02{
              iframe{
                width: 100%;
                height: 184px;
              }
              span{
                margin-top: 9px;
                text-align: right;
                a{
                  color: #3295d6;
                  display: inline-block;
                  position: relative;
                  padding-left: 17px;
                  letter-spacing: 0.05em;
                  text-decoration: underline;
                  &::before{
                    content: "";
                    display: block;
                    background: url(../img/contents/02_company/arrow01.png) 0 0 no-repeat;
                    background-size: 8px;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  }
                }
              }
            }
          }
          .contSubBox02{
            .subBox01{
              margin-top: 35px;
            }
          }
          .contSubBox03{
            .subBox01{
              margin-top: 35px;
              .access{
                margin-top: 37px;
                margin-bottom: 47px;
              }
            }
          }
        }
        #ContBox04{
          margin-top: 75px;
          margin-bottom: 173px;
          h3{
            margin-bottom: 55px;
          }
          ul{
            li{
              margin-bottom: 27px;
              &:last-of-type{
                margin-bottom: 0;
              }
              dl{
                font-size: 0;
                dt{
                  display: inline-block;
                  font-size: 24px;
                  line-height: 32px;
                  letter-spacing: 0.2em;
                  font-weight: bold;
                  font-style: italic;
                  span{
                    letter-spacing: 0.1em;
                    font-size: 36px;
                    color: #facf44;
                    display: inline;
                    font-style: italic;
                  }
                }
                dd{
                  margin-left: 30px;
                  margin-top: 13px;
                  color: #0743a7;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0.15em;
                  background-color: #f5f5f5;
                  position: relative;
                  padding: 17px 22px 14px 27px;
                  &::before{
                    content: "";
                    display: block;
                    width: 24px;
                    height: 1px;
                    background-color: #0743a7;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -30px;
                    margin: auto;
                  }
                  &::after{
                    content: "";
                    display: block;
                    background: url(../img/contents/02_company/icon02.png) 0 0 no-repeat;
                    background-size: 12px;
                    height: 12px;
                    width: 12px;
                    position: absolute;
                    left: -7px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お知らせ
  -------------------------------------*/
  #PageNews.pageIndex {
    #MainImg {
      height: 153px;
      border-bottom: 3px solid #dddddd;
      margin: 0 15px;
      h2{
        top: 28px;
      }
    }
    #TopicPath{
      margin-top: 29px;
    }
    #Main{
      min-height: auto;
      #ContBox01{
        margin-bottom: 163px;
        ul{
          margin-top: 58px;
          margin-bottom: 70px;
          li{
            border-top: 1px solid #dddddd;
            &:last-of-type{
              border-bottom: 1px solid #dddddd;
            }
            dl{
              padding: 26px 0 23px;
              &::after{
                content: "";
                display: block;
                clear: both;
              }
              dt{
                font-size: 14px;
                color: #888;
              }
              dd{
                margin-top: 2px;
                a{
                  position: relative;
                  padding-left: 25px;
                  line-height: 28px;
                  font-size: 16px;
                  font-weight: bold;
                  &::before{
                    content: "";
                    position: absolute;
                    top: 6px;
                    left: 0;
                    display: block;
                    margin: auto;
                    width: 15px;
                    height: 15px;
                    background: url(../img/contents/icon04.png) 0 0 no-repeat;
                    background-size: 15px;
                  }
                }
              }
            }
          }
        }
        .more{
          border-color: #3295d6;
          display: block;
          margin: auto;
          &::before {
            content: "";
            position: absolute;
            top: -20px;
            right: 0;
            left: 0;
            display: block;
            margin: auto;
            width: 1px;
            height: 30px;
            background: #3295d6;
          }
          a{
            color: #3295d6;
            text-align: center;
          }
        }
      }
    }
  }

  /*
  記事詳細ページ
  -------------------------------------*/
  #PageNews.pageEntry {
    #MainImg {
      height: 153px;
      border-bottom: 3px solid #dddddd;
      margin: 0 15px;
      h2{
        top: 28px;
      }
    }
    #TopicPath{
      margin-top: 29px;
    }
    #Main{
      min-height: auto;
      #ContBox01{
        margin-bottom: 190px;
        h3{
          margin-top: 58px;
          margin-bottom: 49px;
          .date{
            padding-top: 39px;
            line-height: 32px;
            font-size: 14px;
            color: #888;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              width: 38px;
              height: 6px;
              background: url(../img/contents/icon05.png) 0 0 no-repeat;
              background-size: 38px auto;
            }
          }
          .title{
            font-size: 34px;
            line-height: 52px;
            font-weight: bold;
            letter-spacing: 0.05em;
          }
        }
        .entryBox{
          padding-bottom: 29px;
          border-bottom: 1px solid #dddddd;
          #attachment_59 {
            width: 100%!important;
          }
          p{
            line-height: 32px;
            letter-spacing: 0.1em;
          }
        }
        #PageNation{
          text-align: center;
          font-size: 0;
          margin-top: 69px;
          li{
            display: inline-block;
            height: 50px;
            border: 1px solid #3295d6;
            border-radius: 25px;
            margin-right: 25px;
            position: relative;
            &:last-of-type{
              margin-right: 0;
            }
            &::before{
              content: "";
              display: block;
              width: 20px;
              height: 1px;
              background-color: #3295d6;
              position: absolute;
            }
            a{
              font-size: 12px;
              letter-spacing: 0.1em;
              color: #3295d6;
              text-align: center;
              line-height: 50px;
              padding: 0 19px;
            }
          }
          .prev{
            &::before{
              right: -10px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
          .list{
            &::before{
              height: 30px;
              width: 1px;
              left: 0;
              right: 0;
              top: -20px;
              margin: auto;
            }
            a{
              padding: 0 15px;
            }
          }
          .next{
            &::before{
              left: -10px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }

  /*
  採用情報
  -------------------------------------*/
  #PageRecruit.pageIndex {
    #MainImg{
      position: relative;
      margin-bottom: 15px;
      background: url(../img/contents/04_recruit/main_img.jpg) center no-repeat;
      background-size: cover;
      &::after{
        content: "";
        position: absolute;
        bottom: -15px;
        display: block;
        width: 100%;
        height: 15px;
        background: url(../img/contents/04_recruit/stripe_r.jpg) 0 0 repeat-x;
        background-size: 1280px;
      }
      h2{
        top: 59px;
        &::before{
          content: "";
          display: block;
          width: 60px;
          height: 60px;
          background: url(../img/contents/04_recruit/icon01.png) 0 0 no-repeat;
          background-size: 60px auto;
        }
        span{
          background: linear-gradient(-90deg, #ff942b, #dc351f);
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255,255,255,0.0);
        }
        .span01{}
        .span02{}
      }
    }
    #Main{
      #ContBox01{
        margin-top: 60px;
        margin-bottom: 80px;
        .contSubBox01{
          border: 1px solid #222;
          padding: 37px 30px 35px;
          position: relative;
          margin-bottom: 95px;
          &::after{
            content: "";
            display: block;
            position: absolute;
            right: -14px;
            bottom: -19px;
            background: url(../img/contents/dot.jpg) 0 0;
            background-size: 9px;
            width: 100%;
            height: 100%;
            z-index: -2;
          }
          h3{
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            border-bottom: 1px solid #222222;
            margin-bottom: 22px;
            padding-bottom: 22px;
            .underline{
              font-size: 34px;
              line-height: 51px;
              letter-spacing: 0.05em;
              display: inline-block;
              position: relative;
              &:last-of-type{
                letter-spacing:0.16em;
              }
              &::after{
                content: "";
                display: block;
                width: 100%;
                height: 10px;
                background-color: #ffa82b;
                position: absolute;
                bottom: 3px;
                z-index: -1;
              }
            }
          }
          p{
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 0.1em;
            margin-bottom: 0;
          }
        }
        .contSubBox02{
          position: relative;
          &::before{
            content: "";
            display: block;
            background: url(../img/contents/04_recruit/stripe_o.jpg) 0 0;
            background-size: 384px;
            width: 90px;
            height: 188px;
            position: absolute;
            right: 0px;
            left: 270px;
            top: -45px;
            margin: auto;
          }
          &::after{
            content: "";
            display: block;
            background: url(../img/contents/04_recruit/stripe_o.jpg) 0 0;
            background-size: 384px;
            width: 75px;
            height: 85px;
            position: absolute;
            left: -300px;
            right: 0;
            top: 286px;
            margin: auto;
          }
          ul{
            width: 345px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            li{
              height: 133px;
              figure{
                font-size: 0;
                .hovImg{
                  width: 133px;
                  display: inline-block;
                }
                figcaption{
                  margin-left: 14px;
                  text-align: center;
                  display: inline-block;
                  position: relative;
                  top: -60px;
                  a{
                    display: inline-block;
                    font-size: 16px;
                    letter-spacing: 0.1em;
                    padding-left: 24px;
                    position: relative;
                    &::before{
                      content: "";
                      display: block;
                      background: url(../img/contents/04_recruit/arrow01.png) 0 0 no-repeat;
                      background-size: 15px;
                      width: 15px;
                      height: 15px;
                      position: absolute;
                      left: 0;
                      bottom: 0;
                      top: 0;
                      margin: auto;
                    }
                  }
                }
              }
            }
            li:nth-of-type(2){
              position: relative;
              left: 45px;
            }
            li:last-of-type{
              position: relative;
              left: 90px;
            }
          }
          span{
            text-align: center;
            .more{
              margin-top: 457px;
              width: 345px;
              height: 70px;
              border-color: #eb7c14;
              border-radius: 35px;
              &::before{
                background-color: #eb7c14;
                height: 40px;
                top: -30px;
              }
              a{
                color: #dc351f;
                font-size: 24px;
                letter-spacing: 0.35em;
                font-weight: bold;
                line-height: 70px;
                position: relative;
                text-align: center;
                &::before{
                  content: "";
                  display: block;
                  background: url(../img/contents/04_recruit/arrow01.png) 0 0 no-repeat;
                  background-size: 15px;
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 24px;
                  margin: auto;
                }
                span{
                  position: relative;
                  display: inline-block;
                  padding-left: 37px;
                  left: 5px;
                  &::after{
                    content: "";
                    display: block;
                    background: url(../img/contents/04_recruit/icon02.png) 0 0 no-repeat;
                    background-size: 24px auto;
                    width: 24px;
                    height: 26px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
      }
      #ContBox02{
        .contSubBox01{
          height: 312px;
          background: linear-gradient(-90deg, rgba(255, 148, 43, .95), rgba(220, 53, 27, .95)), url(../img/contents/04_recruit/recruit_bg.jpg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          .innerBasic{
            position: absolute;
            width: 195px;
            left: 0;
            right: 150px;
            margin: auto;
            &::after{
              content: "";
              display: block;
              background: url(../img/contents/04_recruit/img04_sp.png) 0 0 no-repeat;
              background-size: 160px auto;
              width: 160px;
              height: 276px;
              position: absolute;
              right: 0;
              left: 200px;
              bottom: 0;
              margin: auto;
            }
          }
          dl{
            color: #fff;
            font-weight: bold;
            padding: 54px 0;
            dt{
              position: relative;
              width: 200px;
              margin-bottom: 23px;
              padding-bottom: 16px;
              &::after{
                content: "";
                display: block;
                background-color: #fff;
                width: 130px;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
              }
              .span01{
                font-size: 34px;
                letter-spacing: 0.2em;
                line-height: 49px;
              }
              .span02{
                font-size: 16px;
                line-height: 36px;
                letter-spacing: 0.35em;
              }
            }
            dd{
              font-size: 0;
              span{
                font-size: 24px;
                line-height: 40px;
                letter-spacing: 0.3em;
              }
              .span01{
              }
              .span02{}
            }
          }
        }
        .contSubBox02{
          background-color: #fff5f2;
          padding-bottom: 77px;
          position: relative;
          &::after{
            content: "";
            display: block;
            background: url(../img/contents/04_recruit/img05_sp.png) 0 0 no-repeat;
            background-size: 318px auto;
            width: 318px;
            height: 115px;
            position: absolute;
            bottom: 0;
            right: 0;
          }
          .innerBasic{
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            h4{
              display: inline-block;
              font-size: 24px;
              font-weight: bold;
              line-height: 41px;
              letter-spacing: 0.03em;
              background: linear-gradient(-90deg, #ff942b, #dc351b);
              -webkit-background-clip: text;
              -webkit-text-fill-color: rgba(255, 255, 255, 0);
              margin-top: 41px;
              margin-bottom: 32px;
            }
            p{
              font-size: 16px;
              line-height: 32px;
              letter-spacing: 0.05em;
            }
            .subBox01{
            }
            .subBox02{
            }
          }
        }
      }
      #ContBox03{
        margin-top: 80px;
        margin-bottom: 61px;
        .innerBasic{
          h4{
            margin-bottom: 35px;
          }
          .contSubBox{
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            .subBox01{
              figure{
                position: relative;
                &::after{
                  content: "";
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  z-index: -1;
                }
                img{}
              }
            }
            .subBox02{
              dl{
                margin-top: 44px;
                margin-bottom: 15px;
                dt{
                  font-size: 16px;
                  line-height: 48px;
                  letter-spacing: 0.1em;
                  color: #888;
                  display: inline-block;
                }
                dd{
                  margin-left: 18px;
                  font-size: 24px;
                  line-height: 48px;
                  letter-spacing: 0.35em;
                  font-weight: bold;
                  display: inline-block;
                  vertical-align: bottom;
                }
              }
              h5{
                font-weight: bold;
                color: #dc351f;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.2em;
                margin-bottom: 11px;
              }
              p{}
            }
            .subBox03{
              padding-top: 20px;
              clear: both;
              &::after{
                content: "";
                display: block;
                clear: both;
              }
              .box01{
                h6{
                  padding-left: 51px;
                  padding-bottom: 26px;
                  font-size: 24px;
                  line-height: 32px;
                  letter-spacing: 0.2em;
                  font-weight: bold;
                  color: #dc351f;
                  position: relative;
                  &::before{
                    content: "";
                    display: block;
                    background: url(../img/contents/04_recruit/icon03.png) 0 0 no-repeat;
                    background-size: 38px auto;
                    width: 38px;
                    height: 34px;
                    position: absolute;
                    left: 0;
                    top: -3px;
                  }
                }
              }
              .box02{
                p{}
              }
            }
          }
          .contSubBox01{
            margin-bottom: 40px;
            padding-bottom:15px;
            border-bottom: 1px solid #dddddd;
            .subBox01{
              float: right;
              figure{
                margin-left: 15px;
                &::after{
                  background: linear-gradient(-90deg, #dc351b, #ff942b);
                  left: -15px;
                  bottom: -15px;
                }
              }
            }
            .subBox02{
              float: left;
            }
          }
          .contSubBox02{
            .subBox01{
              float: left;
              figure{
                margin-right: 15px;
                &::after{
                  background: linear-gradient(90deg, #dc351b, #ff942b);
                  right: -15px;
                  bottom: -15px;
                }
              }
            }
            .subBox02{
              float: right;
              dl{
                margin-bottom: 18px;
              }
            }
            .subBox03{
              padding-top: 17px;
            }
          }
        }
      }
      #ContBox04{
        background-color: #fafafa;
        padding-top: 80px;
        padding-bottom: 69px;
        .innerBasic{
          &::after{
            content: "";
            display: block;
            clear: both;
          }
          h4{
            margin-bottom: 57px;
          }
          .contSubBox{
            figure{
              position: relative;
              margin-bottom: 25px;
              padding-bottom: 15px;
              &::after{
                content: "";
                display: block;
                background: url(../img/contents/04_recruit/stripe_r.jpg) 0 0 repeat-x;
                background-size: 17px;
                width: 100%;
                height: 15px;
                position: absolute;
                bottom: 0;
              }
            }
            dl{
              dt{
                position: relative;
                padding-left: 40px;
                font-size: 24px;
                line-height: 48px;
                letter-spacing: 0.1em;
                font-weight: bold;
              }
              dd{
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.09em;
              }
            }
          }
          .contSubBox01{
            margin-bottom: 52px;
            dl{
              dt{
                margin-bottom: 4px;
                &::before {
                  content: "";
                  display: block;
                  background: url(../img/contents/04_recruit/icon04.png) 0 0 no-repeat;
                  background-size: 30px auto;
                  width: 30px;
                  height: 38px;
                  position: absolute;
                  left: 0;
                  top: 5px;
                  bottom: 0;
                }
              }
            }
          }
          .contSubBox02{
            dl{
              dt{
                padding-left: 45px;
                margin-bottom: 3px;
                &::before {
                  content: "";
                  display: block;
                  background: url(../img/contents/04_recruit/icon05.png) 0 0 no-repeat;
                  background-size: 36px auto;
                  width: 36px;
                  height: 31px;
                  position: absolute;
                  left: 0;
                  top: 5px;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
      #ContBox05{
        margin-bottom: 175px;
        .contSubBox01{
          height: 273px;
          background: linear-gradient(-90deg, rgba(255, 148, 43, .95), rgba(220, 53, 27, .95)), url(../img/contents/04_recruit/recruit_bg02.jpg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          .innerBasic{
            padding: 48px 0 0;
            position: relative;
            &::before{
              content: "";
              display: block;
              background: url(../img/contents/04_recruit/img10.png) 0 0 no-repeat;
              background-size: 111px auto;
              width: 111px;
              height: 145px;
              position: absolute;
              right: 117px;
              left: 0;
              bottom: -144px;
              margin: auto;
            }
            &::after{
              content: "";
              display: block;
              background: url(../img/contents/04_recruit/img11.png) 0 0 no-repeat;
              background-size: 96px auto;
              width: 96px;
              height: 136px;
              position: absolute;
              right: 0;
              left: 120px;
              bottom: -145px;
              margin: auto;
            }
            span{
              color: #fff;
              font-weight: bold;
              text-align: center;
            }
            .span01{
              font-size: 34px;
              line-height: 49px;
              letter-spacing: 0.2em;
            }
            .span02{
              font-size: 16px;
              line-height: 32px;
              letter-spacing: 0.35em;
            }
          }
        }
        .contSubBox02{
          margin-top: 30px;
          p{
            font-weight: bold;
            font-size: 20px;
            line-height: 42px;
            letter-spacing: 0.1em;
          }
          table{
            border-top: 1px solid #dddddd;
            margin-top: 37px;
            width: 100%;
            tr{
              border-bottom: 1px solid #dddddd;
              th{
                padding-top: 23px;
                font-size: 16px;
                font-weight: bold;
                line-height: 32px;
                letter-spacing: 0.35em;
                display: block;
              }
              td{
                padding-bottom: 23px;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.1em;
                display: block;
                position: relative;
                top: 2px;
                span{
                  color: #888;
                  display:inline;
                  padding-left: 25px;
                }
                a{
                  display: inline-block;
                  font-size: 24px;
                  line-height: 32px;
                  letter-spacing: 0em;
                  margin-top: 3px;
                  font-weight: bold;
                  color: #dc351f;
                  font-style: italic;
                  text-decoration: underline;
                  padding-left: 10px;
                }
              }
            }
            tr:last-of-type{
              td{
                padding-bottom: 27px;
              }
              span{
                color: #222;
                font-size: 14px;
                line-height: 32px;
                padding-left: 0;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact.pageIndex {
    #MainImg{
      margin: 0 15px;
      height: 153px;
      border-bottom: 3px solid #dddddd;
      h2{
        top: 28px;
        span{
          background: linear-gradient(-90deg, #f3cb49, #f09937);
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255,255,255,0.0);
        }
        .span01{
          letter-spacing: 0.15em;
        }
        .span02{
          position: relative;
          top: -2px;
        }
      }
    }
    #TopicPath{
      margin-top: 29px;
    }
    #Main{
      #ContBox01{
        margin-top: 49px;
        margin-bottom: 101px;
        .innerBasic{
          p{
            a{
              display: inline;
              color: #3295d6;
              text-decoration: underline;
            }
          }
          table{
            width: 100%;
            border-top: 1px solid #dddddd;
            margin-top: 40px;
            margin-bottom: 50px;
            padding-top: 20px;
            display: block;
            tbody{display: block;}
            tr{
              border-bottom: 1px dotted #dddddd;
              display: block;
              &:last-of-type{
                border: none;
              }
              th,td{
                display: block;
              }
              th{
                font-size: 16px;
                padding-left: 48px;
                padding-top: 29px;
                letter-spacing: 0.05em;
                position: relative;
                vertical-align: top;
                &::before{
                  content: "";
                  display:block;
                  width: 33px;
                  height: 18px;
                  border-radius:2px;
                  font-size: 11px;
                  color: #fff; 
                  line-height: 18px;
                  text-align: center;
                  letter-spacing: 0.05em;
                  position: absolute;
                  top: 31px;
                  left: 0;
                }
              }
              th.require{
                &::before{
                  content: "必須";
                  background-color: #dc351f;
                }
              }
              th.any{
                &::before{
                  content: "任意";
                  background-color: #b1b1b1;
                }
              }
              td{
                padding: 10px 0 30px;
                label{
                  margin-right: 21px;
                  position: relative;
                  top: 4px;
                  &:last-of-type{
                    margin-right: 0;
                  }
                  .radio {
                    display: inline-block;
                    margin-right: 10px;
                    span{
                      display: inline-block;
                      width: 30px;
                      height: 30px;
                      background-color: #fafafa;
                      border: 1px solid #cccccc;
                      border-radius: 50%;
                      position: relative;
                      top: -4px;
                      input{
                        opacity:0;
                      }
                    }
                    span.checked{
                      &::after{
                        content: "";
                        display: block;
                        width: 11px;
                        height: 11px;
                        border-radius: 50%;
                        background-color: #ffa33b;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                      }
                    }
                  }
                }
                input{
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                input:focus,
                textarea:focus{
                  border-color: #ffa33b;
                  outline: 0;
                }
                input,textarea{
                  background-color: #fafafa;
                  border: 1px solid #cccccc;
                  letter-spacing: 0.05em;
                  resize: vertical;
                  width: 100%;
                  padding: 8px 15px 7px;
                  font-size: 16px;
                  &::placeholder{
                    color: #bbbbbb;
                  }
                }
                textarea{
                  height: 298px;
                }
                input.size03{
                  width: 144px;
                }
              }
              td.zip{
                padding-left: 21px;
                position: relative;
                &::before{
                  content: "〒";
                  font-weight: bold;
                  display: block;
                  position: absolute;
                  left: 0;
                  top: 21px;
                }
                #zip{
                  margin-bottom: 15px;
                }
                #zip.wpcf7c-conf{
                  margin-bottom: 0;
                }
                input.wpcf7c-conf {
                  padding: 0;
                }
              }
              .zip.conf{
                &::before{
                  top: 12px;
                }
              }
            }
            tr.textarea{
              td{
                padding-bottom: 24px;
              }
            }
            tr.select{
              td{
                select{
                  width: 100%;
                  background-color: #fafafa;
                  position: relative;
                  background: url(../img/contents/icon06.png) center right 20px no-repeat;
                  background-size: 10px;
                  padding: 8px 20px 7px;
                  border: 1px solid #ccc;
                }
              }
            }
            tr.radio01{
              th{
                padding-top: 35px;
                &::before{
                  top: 37px;
                }
              }
            }
            tr.radio02{
              th{
                padding-top: 27px;
                &::before{top: 29px;}
              }
              td{padding-top: 11px;}
            }
          }
          .btn01{
            margin: auto;
            width: 320px;
            height: 70px;
            line-height: 70px;
            border-radius: 35px;
            font-size: 16px;
            letter-spacing: 0.1em;
          }
        }
      }
      #ContBox02{
        background-color: #fafafa;
        padding-top: 80px;
        .innerBasic{
          .contSubBox{
            border-bottom: 1px solid #dddddd;
            padding-bottom: 54px;
            &::after{
              content: "";
              display: block;
              clear: both;
            }
            h4{
              padding-top: 33px;
              margin-bottom: 46px;
              .span01{
                line-height: 36px;
              }
            }
            .subBox{
              p{
                letter-spacing: 0.05em;
              }
              p.num{
                padding-left: 15px;
                position: relative;
                &::before{
                  content: "";
                  display: block;
                  font-size: 16px;
                  line-height: 32px;
                  letter-spacing:0.05em;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
              p.num01{&::before{content: "1.";}}
              p.num02{&::before{content: "2.";}}
              p.num03{&::before{content: "3.";}}
              p.num04{&::before{content: "4.";}}
              p.num05{&::before{content: "5.";}}
              p.num06{&::before{content: "6.";}}
              span{
                text-align: right;
                padding-top: 4px;
                font-size: 16px;
              }
            }
            .subBox01{
            }
            .subBox02{
            }
          }
          .contSubBox02{
            padding-bottom: 175px;
            h4{
              margin-top: 60px;
            }
            h5{
              font-size: 20px;
              line-height: 32px;
              letter-spacing: 0.1em;
              font-weight: bold;
              margin-bottom: 10px;
            }
            p{
              margin-bottom: 31px;
            }
          }
        }
      }
    }
    .wpcf7-form-control-wrap.corporation{
      display:inline-block;
      &:first-of-type{
        margin-right: 21px;
      }
    }
    .wpcf7-form-control-wrap.method{
      display: inline-block;
      margin-right: 21px;
      &:last-of-type{
        margin-right: 0;
      }
    }
    .wpcf7-list-item-label{
      display: inline-block;
    }
    span.wpcf7-list-item{
      margin-left: 0;
    }
  }

  /*
  確認画面
  -------------------------------------*/
  #PageContact.pageIndex{
   input.wpcf7c-conf, textarea.wpcf7c-conf, select.wpcf7c-conf {
    background: none!important;
    border: none!important;
    padding-left: 0!important;
    padding-right: 0!important;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  tr.radio02 th.wpcf7c-elm-step2{
    padding-top: 33px;
    &::before{
      top: 35px;
    }
  }
  .prevBox{
    text-align: center;
  }
  .wpcf7-submit{
    margin: auto;
    border-radius: 35px;
    width: 320px;
    height: 70px;
    line-height: 70px;
    letter-spacing: .1em;
    font-size: 16px;
  }
  .prev{
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #3295d6;
    display: inline-block;
    margin-top: 37px;
    margin-bottom: 70px;
    text-decoration: underline;
  }
  .ajax-loader{
    display: none;
  }
}

  /*
  送信完了画面
  -------------------------------------*/
  #PageContact.pageThanks {
    #MainImg{
      margin: 0 15px;
      height: 153px;
      border-bottom: 3px solid #dddddd;
      h2{
        top: 28px;
        span{
          background: linear-gradient(-90deg, #f3cb49, #f09937);
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255,255,255,0.0);
        }
        .span01{
          letter-spacing: 0.15em;
        }
        .span02{
          position: relative;
          top: -2px;
        }
      }
    }
    #TopicPath{
      margin-top: 29px;
    }
    #Main{
      min-height: auto;
      #ContBox01{
        margin-top: 41px;
        margin-bottom: 192px;
        p{
        }
        .addressBox{
          margin-top: 30px;
          height: 78px;
          margin: auto;
          text-align: center;
          border: 1px solid #222222;
          position: relative;
          &::after {
            content: '';
            display: block;
            background: url(../img/contents/dot.jpg) 0 0;
            background-size: 5px;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 15px;
            top: 15px;
            z-index:-1;
          }
          a{
            margin: 19px 0;
            display: inline-block;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.1em;
            position: relative;
            &::after{
              content: "";
              display: block;
              height: 2px;
              width: 100%;
              position: absolute;
              bottom: 5px;
              background-color: #222;
            }
          }
        }
        .moreBox{
          text-align: center;
          .more{
            width: 188px;
            height: 50px;
            margin-top: 76px;
            border-color: #3295d6;
            &::before{
              background-color: #3295d6;
            }
            a{
              color: #3295d6;
              line-height: 50px;
            }
          }
        }
      }
    }
  }


}

@media screen and (max-width: 374px) { //320px端末向け
  #Page.pageIndex #MainImg .mainImgBox h2{
    bottom: -84px;
    left: -5px;
  }
  #PageCompany.pageIndex .innerBasic #Main #ContBox02 ul li dl dt {
    width: 105px;
    letter-spacing: 0.15em;
  }
  #PageNews.pageEntry #Main #ContBox01 #PageNation li{
    margin-right: 14px;
  }
  #PageNews.pageEntry #Main #ContBox01 #PageNation li a{
    padding: 0 12px;
  }
  #PageNews.pageEntry #Main #ContBox01 #PageNation .list a{
    padding: 0 10px;
  }
  #PageRecruit.pageIndex #Main #ContBox01 .contSubBox01 h3 .underline{
    letter-spacing: -0.04em;
  }
  #PageRecruit.pageIndex #Main #ContBox01 .contSubBox01 h3 .underline:last-of-type{
    letter-spacing: 0em;
  }
  #PageRecruit.pageIndex #Main #ContBox01 .contSubBox02 ul li:nth-of-type(2),
  #PageRecruit.pageIndex #Main #ContBox01 .contSubBox02 ul li:nth-of-type(3){
    left: 0;
  }
  #PageRecruit.pageIndex #Main #ContBox01 .contSubBox02::after{
    z-index: -1;
  }
  #PageRecruit.pageIndex #Main #ContBox01 .contSubBox02 span .more{
    max-width:345px;
    width: 100%;
  }
  #PageRecruit.pageIndex #Main #ContBox02 .contSubBox01 .innerBasic{
    right: 95px;
  }
  #PageRecruit.pageIndex #Main #ContBox02 .contSubBox01 .innerBasic::after{
    left: 165px;
    width: 140px;
    height: 241px;
    background-size: 140px auto;
  }
  #PageRecruit.pageIndex #Main #ContBox05 .contSubBox01 .innerBasic .span01{
    letter-spacing:0.07em;
  }
  #PageContact.pageIndex .wpcf7-form-control-wrap.method{
    margin-right: 7px;
  }
  #PageContact.pageIndex #Main #ContBox01 .innerBasic .btn01{
    max-width: 320px;
    width:100%;
  }
  #PageContact.pageThanks #Main #ContBox01 .addressBox a{
    letter-spacing: 0.05em;
  }
}